var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function Fab(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) {
        var defaultColor = ownerState.color === 'default';
        var inheritColor = ownerState.color === 'inherit';
        var circularVariant = ownerState.variant === 'circular';
        var extendedVariant = ownerState.variant === 'extended';
        var outlinedVariant = ownerState.variant === 'outlined';
        var outlinedExtendedVariant = ownerState.variant === 'outlinedExtended';
        var softVariant = ownerState.variant === 'soft';
        var softExtendedVariant = ownerState.variant === 'softExtended';
        var defaultStyle = __assign(__assign(__assign({ '&:hover, &:active': {
                boxShadow: 'none',
            } }, ((circularVariant || extendedVariant) && __assign(__assign({}, ((defaultColor || inheritColor) && {
            color: theme.palette.grey[800],
            boxShadow: theme.customShadows.z8,
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
            },
        })), (inheritColor && __assign({}, (!isLight && {
            color: 'inherit',
            backgroundColor: theme.palette.grey[800],
            '&:hover': {
                backgroundColor: theme.palette.grey[700],
            },
        })))))), ((outlinedVariant || outlinedExtendedVariant) && __assign(__assign({ boxShadow: 'none', backgroundColor: 'transparent' }, ((defaultColor || inheritColor) && {
            border: "solid 1px ".concat(alpha(theme.palette.grey[500], 0.32)),
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        })), (defaultColor && __assign({}, (!isLight && {
            color: theme.palette.text.secondary,
        })))))), ((softVariant || softExtendedVariant) && __assign(__assign({ boxShadow: 'none' }, (defaultColor && {
            color: theme.palette.grey[800],
            backgroundColor: theme.palette.grey[300],
            '&:hover': {
                backgroundColor: theme.palette.grey[400],
            },
        })), (inheritColor && {
            backgroundColor: alpha(theme.palette.grey[500], 0.08),
            '&:hover': {
                backgroundColor: alpha(theme.palette.grey[500], 0.24),
            },
        }))));
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && __assign(__assign(__assign({}, ((circularVariant || extendedVariant) && {
            boxShadow: theme.customShadows[color],
            '&:hover': {
                backgroundColor: theme.palette[color].dark,
            },
        })), ((outlinedVariant || outlinedExtendedVariant) && {
            color: theme.palette[color].main,
            border: "solid 1px ".concat(alpha(theme.palette[color].main, 0.48)),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.08),
                border: "solid 1px ".concat(theme.palette[color].main),
            },
        })), ((softVariant || softExtendedVariant) && {
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            backgroundColor: alpha(theme.palette[color].main, 0.16),
            '&:hover': {
                backgroundColor: alpha(theme.palette[color].main, 0.32),
            },
        }))))); });
        var disabledState = {
            '&.Mui-disabled': __assign({}, ((outlinedVariant || outlinedExtendedVariant) && {
                backgroundColor: 'transparent',
                border: "solid 1px ".concat(theme.palette.action.disabledBackground),
            })),
        };
        var size = __assign({}, ((extendedVariant || outlinedExtendedVariant || softExtendedVariant) && __assign(__assign(__assign({ width: 'auto', '& svg': {
                marginRight: theme.spacing(1),
            } }, (ownerState.size === 'small' && {
            height: 34,
            minHeight: 34,
            borderRadius: 17,
            padding: theme.spacing(0, 1),
        })), (ownerState.size === 'medium' && {
            height: 40,
            minHeight: 40,
            borderRadius: 20,
            padding: theme.spacing(0, 2),
        })), (ownerState.size === 'large' && {
            height: 48,
            minHeight: 48,
            borderRadius: 24,
            padding: theme.spacing(0, 2),
        }))));
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle, disabledState, size], false);
    };
    return {
        MuiFab: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
    };
}
